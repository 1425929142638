import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from './firebaseConfig'; // Import Firebase auth and db
import { ref, get } from 'firebase/database'; // Import Firebase Realtime Database functions
import backgroundImage from './homepageBackground.jpg'; // Import the background image

function CategoryPage() {
  const { category } = useParams(); // Get the category from the URL
  const navigate = useNavigate(); // For redirecting users
  const [accessDenied, setAccessDenied] = useState(false); // State to track access status
  const [loading, setLoading] = useState(true); // State to manage loading status

  // Disable right-click functionality
  useEffect(() => {
    const disableRightClick = (e) => e.preventDefault();
    document.addEventListener('contextmenu', disableRightClick);
    
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);

  // Hardcoded file links for each category
  const categoryFiles = {
    stab52: {
      pdfs: [
        {name:'STAB52_公开课_学生_new', url: 'https://storage.googleapis.com/videos-courses-web/24F/stab52/STAB52_%E5%85%AC%E5%BC%80%E8%AF%BE_%E5%AD%A6%E7%94%9F_new.pdf'},
        { name: 'STAB52_B53_Class1_额外习题_学生', url: 'https://storage.googleapis.com/videos-courses-web/24F/stab52/STAB52_B53_Class1_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%98_%E5%AD%A6%E7%94%9F.pdf' },
        //{ name: 'Lecture 2 Notes', url: 'https://example.com/stab52_lecture2.pdf' },
        {name:'STAB52 Class2_学生',url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/STAB52%20Class2_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52_Class2_额外习题_学生', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/week3/STAB52_Class2_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%98_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52 Class3_学生_new', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/week3/STAB52%20Class3_%E5%AD%A6%E7%94%9F_new.pdf'},
        {name:'TT1 REVIEW PDF', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt1/STAB52%20TT1_1_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'TT1 REVIEW PDF 2', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt1/STAB52%20TT1_2_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52_额外习题4_学生.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/class5/STAB52_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%984_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52 Class5_学生_new.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/class5/STAB52%20Class5_%E5%AD%A6%E7%94%9F_new.pdf'},
        {name:'STAB52 Class 6 PDF', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/october%2020/STAB52%20Class6_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52 extra practice 5 PDF', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/october%2020/STAB52_B53_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%985_%E5%AD%A6%E7%94%9F.pdf.pdf'},
        {name:'STAB52 Class7_学生.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/class7/STAB52%20Class7_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52 Extra Practice 6 pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt2/STAB52_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%986_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52 TT2 part1 pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt2/STAB52%20TT2_part1_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'STAB52 TT2 Part2 pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt2/STAB52%20TT2_part2_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'常用integral.公式总结pdf.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt2/%E5%B8%B8%E7%94%A8integral.%E5%85%AC%E5%BC%8F%E6%80%BB%E7%BB%93pdf.pdf'},


      ],
      videos: [
        { name: 'Week 1试听课', url: 'https://storage.googleapis.com/videos-courses-web/24F/stab52/Week1%E8%AF%95%E5%90%AC%E8%AF%BE.mp4' },
        {name: "额外练习题1",url :'https://storage.googleapis.com/videos-courses-web/24F/stab52/stab52_class1_%E9%A2%9D%E5%A4%96%E7%BB%83%E4%B9%A0%E9%A2%981.mp4'},
        {name:'Class 2 Week 2', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/Class2_week2.mp4'},
        {name:'STAB52.B53_Class2_额外习题', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/week3/STAB52.B53_Class2_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%98.mp4'},
        {name:'STAB52.53 (2024) FALL Weekly Course-Week 03-LECTURE', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/week3/STAB52.53%20(2024)%20FALL%20Weekly%20Course-Week%2003-LECTURE.mp4'},
        {name:'B52_TT1REVIEW', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt1/B52_TT1REVIEW.mp4'},
        {name:'B52_TT1REVIEW2', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt1/B52_TT1REVIEW2.mp4'},
        {name:'STAB52_额外习题4_学生 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/class5/STAB52_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%984_%E5%AD%A6%E7%94%9F.mp4'},
        {name:'STAB52 Class 5 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/class5/class%205.mp4'},
        {name:'STAB52 Class 6 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/october%2020/STAB52.53%20(2024)%20FALL%20Weekly%20Course-Week%2006-stab52.mp4'},
        {name:'STAB52 extra practice 5 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/october%2020/Untitled%20video%20-%20Made%20with%20Clipchamp.mp4.mp4'},
        {name:'STAB52 Class 7 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/class7/STAB52.53%20(2024)%20FALL%20Weekly%20Course-Week%2007-stab52.mp4'},
        {name:'STAB52 Extra Practice 6 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt2/STAB52_%E9%A2%9D%E5%A4%96%E4%B9%A0%E9%A2%986_%E8%A7%86%E9%A2%91.mp4.mp4'},
        {name:'STAB52 TT2 Part1 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt2/STAB52.53%20(2024)%20FALL%20Term%20Test%202-review-Midterm%20Review.mp4'},
        {name:'STAB52 TT2 Part2 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/stab52/tt2/STAB52.53%20(2024)%20FALL%20Term%20Test%202-review-Midterm%20Review2.mp4'},


        
      ],

    },
    // Add other categories here
    matb41:{
      pdfs: [
          {name:'MATB41 class1 公开课', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/MATB41%20class1%20%E5%85%AC%E5%BC%80%E8%AF%BE.pdf'},
          {name: 'MATB41 class2', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/MATB41%20class2.pdf'},
          {name:'MATB41 class2 practice problem with sol', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/MATB41%20class2%20practice%20problem%20with%20sol.pdf'},
          {name:'MATB41 class3', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/week3/MATB41%20class3.pdf'},
          {name:'MATB41 class3 practice problem with sol', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/week3/MATB41%20class3%20practice%20problem%20with%20sol.pdf'},
          {name:'MATB41 class 4', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class4/MATB41%20class4.pdf'},
          {name:'MATB41 TT P1', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/MATB41%20midterm%20review%20part1.pdf'},
          {name:'MATB41 TT P2', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/MATB41%20midterm%20review%20part2.pdf'},
          {name:'MATB41 2022F practice midterm-sol.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/MATB41%202022F%20practice%20midterm-sol.pdf'},
          {name:'MATB41 2024S Practice_Test_1.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/MATB41%202024S%20Practice_Test_1.pdf'},
          {name:'MATB41 2024S MIDTERM-SOL.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/MATB41%202024S%20MIDTERM-SOL.pdf'},
          {name:'MATB41 Class 5', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class5/MATB41%20class5.pdf'},
          {name:'MATB41 class5 practice problem with sol.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class5/MATB41%20class5%20practice%20problem%20with%20sol.pdf'},
          {name:'MATB41 class6.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class6/MATB41%20class6.pdf'},
          {name:'MATB41 class6 practice problem with sol.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class6/MATB41%20class6%20practice%20problem%20with%20sol.pdf'},
          {name:'MATB41 Class7', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class7/MATB41%20class7.pdf'},
          {name:'MATB41 class7 practice problem with sol.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class7/MATB41%20class7%20practice%20problem%20with%20sol.pdf'},
          {name:'MATB41 Class8 PDF', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class8/MATB41%20class8.pdf'},
          {name:'MATB41 Class8 practice problems', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class8/MATB41%20class8%20practice%20problem%20with%20sol.pdf'},
          {name:'MATB41 常用integral总结.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class8/%E5%B8%B8%E7%94%A8integral%E6%80%BB%E7%BB%93.pdf'},

      ],
      videos: [
          { name: 'Week 1试听课', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week1%20%E8%AF%95%E5%90%AC%E8%AF%BE.mp4' },
          { name: 'Week 02 LECTURE 课程回放', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week%2002%20LECTURE%20%E8%AF%BE%E7%A8%8B%E5%9B%9E%E6%94%BE.mp4'},
          {name: 'Week 02 习题课 Assignment1讲解更新', url: 'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week%2002%20%E4%B9%A0%E9%A2%98%E8%AF%BE%20Assignment1%E8%AE%B2%E8%A7%A3%E6%9B%B4%E6%96%B0.mp4'},
          {name:'Week 02 LECTURE MATB41 CLASS2补充', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week%2002%20LECTURE%20MATB41%20CLASS2%E8%A1%A5%E5%85%85.mp4'},
          {name:'Week 02 LECTURE Assignment2讲解更新Q3单独录', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/Week%2002%20LECTURE%20Assignment2%E8%AE%B2%E8%A7%A3%E6%9B%B4%E6%96%B0Q3%E5%8D%95%E7%8B%AC%E5%BD%95.mp4'},
          {name:'A2_Q3', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/Q3.mp4'},
          {name:'MATB41 (2024) FALL Weekly Course-Week 03-LECTURE', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/week3/MATB41%20(2024)%20FALL%20Weekly%20Course-Week%2003-LECTURE.mp4'},
          {name:'MATB41_Assignment3 VIDEO', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/A3/MATB41_A3.mp4'},
          {name:'MATB41 class4 video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class4/class4.mp4'},
          {name:'MATB41 Assignment4', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/A4/matb41_A4.mp4'},
          {name:'MATB41 TT P1 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/matb41TT_PART1.mp4'},
          {name:'MATB41 TT P2 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/matb41TT_PART2.mp4'},
          {name:'MATB41 24F Practice TT', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/termtest/MATB41%202024F%20Practice_Test.mp4.mp4'},
          {name:'MATB41 Class 5 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class5/matb41%20class%205.mp4'},
          {name:'MATB41 A5 Q1 - Q5 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/A5/Assignment5%20Q1-Q5.mp4.mp4'},
          {name:'MATB41 A5 Q6 - Q9 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/A5/assignment5%20Q6-Q9.mp4.mp4'},
          {name:'Class 6 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class6/MATB41%20(2024)%20FALL%20Weekly%20Course-Week%2007-LECTURE.mp4'},
          {name:'Assignment6 Q1-Q4.mp4.mp4', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class6/Assignment6%20Q1-Q4.mp4.mp4'},
          {name:'Assignment6 Q5-8.mp4.mp4', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class6/Assignment6%20Q5-8.mp4.mp4'},
          {name:'Assignment 6 Q9 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class6/%E9%83%A8%E5%88%86%E4%BA%BA%E7%9A%84Q9.MP4.mp4'},
          {name:'MATB41 Class7 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class7/MATB41%20(2024)%20FALL%20Weekly%20Course-Week%2008-LECTURE.mp4'},
          {name:'Assignment 7 Part 1 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class8/Assignment7%20Q1-Q4.mp4.mp4'},
          {name:'Assignment7 Part2 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class8/Assignment7%20Q5-7.mp4.mp4'},
          {name:'MATB41 Class 8 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb41/class8/MATB41%20(2024)%20FALL%20Weekly%20Course-Week%2009-LECTURE.mp4'},
      ],
  },
  actb40:{
    pdfs:[
        {name: 'ACTB40 Class1', url: 'https://storage.googleapis.com/videos-courses-web/24F/actb40/ACTB40%20Class1.pdf'},
        {name: "Quiz 1", url : 'https://storage.googleapis.com/videos-courses-web/24F/actb40/quiz%201.pdf'},
        {name:'ACTB40 Class2', url: 'https://storage.googleapis.com/videos-courses-web/24F/actb40/ACTB40%20Class2.pdf'},
        {name:'ACTB40 Class3.pdf', url: 'https://storage.googleapis.com/videos-courses-web/24F/actb40/class3/ACTB40%20Class3.pdf'},
        {name:'ACTB40 Class4.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/week4/ACTB40%20Class4.pdf'},
        {name:'ACTB40_TT1_REVIEW_PART1',url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt1/ACTB40%20Term%20Test%20Review%201.pdf'},
        {name:'ACTB40_TT1_REVIEW_PART2', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt1/ACTB40%20Term%20Test%20Review%202.pdf'},
        {name:'ACTB40 Class5.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class5/ACTB40%20Class5.pdf'},
        {name:'ACTB40 Class6.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class6/ACTB40%20Class6.pdf'},
        {name:'ACTB40 Class7.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class7/ACTB40%20Class7.pdf'},
        {name:'ACTB40 Class8 pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class8/ACTB40%20Class8.pdf'},
        {name:'ACTB40 TT2 Part1 pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt2/ACTB40%20TT2%20Review%201.pdf'},
        {name:'ACTB40 TT2 Part2 pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt2/ACTB40%20TT2%20Review%202.pdf'},

        

    ],
    videos:[
        {name: "Week 1试听课", url :'https://storage.googleapis.com/videos-courses-web/24F/actb40/week1%E8%AF%95%E5%90%AC%E8%AF%BE.mp4'},
        {name: "Week 2 Quiz 1",url: 'https://storage.googleapis.com/videos-courses-web/24F/actb40/ACTB40-Week%2002%20LECTURE%20quiz1.mp4'},
        {name:'Week 03 LECTURE 课程回放', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/Week%2003%20LECTURE%20%E8%AF%BE%E7%A8%8B%E5%9B%9E%E6%94%BE.mp4'},
        {name:'Week 4', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class3/ACTB40_Class3.mp4'},
        {name:'Week 04 加课 GMT20240925-235147_Recording_1920x1440.mp4.', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/week4/Week%2004%20%E5%8A%A0%E8%AF%BE%20GMT20240925-235147_Recording_1920x1440..mp4'},
        {name:'ACTB40_TT1_REVIEW_PART1_VIDEO', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt1/ACTB40_TT1_REVIEW_PART1.mp4'},
        {name:'ACTB40_TT1_REVIEW_PART2_VIDEO', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt1/ACTB40_TT1_REVIEW_PART2.mp4'},
        {name:'ACTB40 Class 5 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class5/ACTB40%20(2024)%20FALL%20Weekly%20Course-Week%2006-LECTURE.mp4'},
        {name:'ACTB40 Class 5 加课 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class5/ACTB40%20(2024)%20FALL%20Weekly%20Course-Week%2006-%E5%8A%A0%E8%AF%BE.mp4'},
        {name:'ACTB40 Class 6 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class6/ACTB40%20(2024)%20FALL%20Weekly%20Course-Week%2007-LECTURE.mp4'},
        {name:'ACTB40 class7.mp4', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class7/actb40%20class7.mp4'},
        {name:'ACTB40 Class8 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/class8/ACTB40%20(2024)%20FALL%20Weekly%20Course-Week%2009-LECTURE.mp4'},
        {name:'ACTB40 TT2 Video Part1', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt2/GMT20241111-010457_Recording_1920x1440.mp4%20(2).mp4'},
        {name:'ACTB40 TT2 Video Part2', url:'https://storage.googleapis.com/videos-courses-web/24F/actb40/tt2/ACTB40%20(2024)%20FALL%20Midterm%20Review-review-Test%20Review%202.mp4'}

    ],
},
matb44:{
    pdfs:[
        {name:'MATB44公开课_学生', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/MATB44%E5%85%AC%E5%BC%80%E8%AF%BE_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44_class2_学生', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/MATB44_class2_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44_Assignment_1_学生.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/A1/MATB44_Assignment_1_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44_class3_学生.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/week4/MATB44_class3_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATb44 Class4 PDF', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/class4/MATB44_class4_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44_Assignment_2_学生.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/A2/MATB44_Assignment_2_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44 Midterm Review Part1', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/midterm/MATB44_Midterm_review1_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44 Midterm Review Part2', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/midterm/MATB44_Midterm_review2_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44_class5_学生.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/Class5/MATB44_class5_%E5%AD%A6%E7%94%9F.pdf'},
        {name:'MATB44_class6_学生.pdf', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/class6/MATB44_class6_%E5%AD%A6%E7%94%9F.pdf'},
    ],
    videos:[
        {name: "Week 1试听课", url: "https://storage.googleapis.com/videos-courses-web/24F/matb44/Week1%20%E8%AF%95%E5%90%AC%E8%AF%BE.mp4"},
        {name:'Class 2 Week 2', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/Class%202%20Week%202.mp4'},
        {name:'Assignment1_解析视频', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/A1/Assignment1_%E8%A7%A3%E6%9E%90%E8%A7%86%E9%A2%91.mp4'},
        {name:'MATB44 (2024) FALL Weekly Course-Week 04-LECTURE', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/week4/MATB44%20(2024)%20FALL%20Weekly%20Course-Week%2004-LECTURE.mp4'},
        {name:'MATB44 Class 4', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/class4/MATB44.mp4'},
        {name:'A2 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/A2/Untitled%20video%20-%20Made%20with%20Clipchamp.mp4.mp4'},
        {name:'MATB44 Midterm Review Part1 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/midterm/MATB44%20(2024)%20FALL%20Midterm%20Review-review-Midterm%20Review.mp4'},
        {name:'MATB44 Midterm Review Part 2 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/midterm/MATB44%20(2024)%20FALL%20Midterm%20Review-review-Midterm%20Review2.mp4'},
        {name:'MATB44_class5 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/Class5/MATB44%20(2024)%20FALL%20Weekly%20Course-Week%2006-LECTURE.mp4'},
        {name:'MATB44_Class6 Video', url:'https://storage.googleapis.com/videos-courses-web/24F/matb44/class6/MATB44%20(2024)%20FALL%20Weekly%20Course-Week%2007-LECTURE.mp4'},

    ],
},
};

  useEffect(() => {
    const checkAccess = () => {
      auth.onAuthStateChanged(user => {
        if (user) {
          const uid = user.uid;
          const categoryNumber = getCategoryNumber(category); 
          const categoryRef = ref(db, `users/${uid}/categories/Category${categoryNumber}`);

          get(categoryRef).then(snapshot => {
            if (snapshot.exists() && snapshot.val()) {
              setLoading(false); // User has access
            } else {
              setAccessDenied(true); // User doesn't have access
              setLoading(false);
            }
          }).catch(error => {
            console.error("Error checking access:", error);
            setAccessDenied(true);
            setLoading(false);
          });
        } else {
          setAccessDenied(true); // No user is logged in
          setLoading(false);
        }
      });
    };

    checkAccess();
  }, [category]);

  const getCategoryNumber = (categoryName) => {
    switch (categoryName.toLowerCase()) {
      case 'stab52': return 1;
      case 'matb41': return 2;
      case 'matb44': return 3;
      case 'actb40': return 4;
      case 'mgeb02': return 5;
      default: return 0;
    }
  };

  if (loading) {
    return <div style={loadingStyle}>Loading...</div>; 
  }

  if (accessDenied) {
    return (
      <div style={accessDeniedStyle}>
        <div style={accessDeniedBoxStyle}> {/* Transparent box for the Access Denied message */}
          <h1>Access Denied</h1>
          <p>You do not have access to this category.</p>
          <button onClick={() => navigate('/')} style={backButtonStyle}>Go back to Home</button>
        </div>
      </div>
    );
  }

  // Get the files for the current category
  const files = categoryFiles[category.toLowerCase()] || { pdfs: [], videos: [] };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Files for {category.toUpperCase()}</h1>

      <div style={transparentBoxStyle}>
        {/* Section for PDF Files */}
        <div style={sectionStyle}>
          <h2>PDF Files</h2>
          <ul style={fileListUlStyle}>
            {files.pdfs.length > 0 ? (
              files.pdfs.map((file, index) => (
                <li key={index} style={fileItemStyle}>
                  <a href={file.url} target="_blank" rel="noopener noreferrer" style={fileLinkStyle}>
                    {file.name}
                  </a>
                </li>
              ))
            ) : (
              <p style={noFilesTextStyle}>No PDF files available for this category.</p>
            )}
          </ul>
        </div>

        {/* Section for Videos */}
        <div style={sectionStyle}>
          <h2>Videos</h2>
          <div style={videoContainerStyle}>
            {files.videos.length > 0 ? (
              files.videos.map((file, index) => (
                <div key={index} style={videoItemStyle}>
                  <h3>{file.name}</h3>
                  <video controls controlsList="nodownload" width="600" style={videoStyle}>
                    <source src={file.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ))
            ) : (
              <p style={noFilesTextStyle}>No videos available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

// CSS Styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
};

const titleStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  padding: '20px',
  fontSize: '28px',
  fontWeight: 'bold',
  zIndex: 1,
  textAlign: 'center',
  width: '100%',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
};

const transparentBoxStyle = {
  flexGrow: 1,
  overflowY: 'scroll',
  width: '80%',
  maxWidth: '900px',
  padding: '20px',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '15px',
  color: '#fff',
  marginTop: '20px',
  textAlign: 'center',
};

const sectionStyle = {
  marginBottom: '40px',
};

const fileListUlStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
};

const fileItemStyle = {
  margin: '10px 0',
  fontSize: '18px',
};

const fileLinkStyle = {
  textDecoration: 'none',
  color: '#00bfff',
  fontWeight: 'bold',
  transition: 'color 0.2s',
};

const noFilesTextStyle = {
  fontSize: '18px',
  color: '#fff',
};

const videoContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const videoItemStyle = {
  marginBottom: '30px',
  textAlign: 'center',
};

const videoStyle = {
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  fontSize: '24px',
  color: '#333',
};

const accessDeniedStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark transparent background for full screen
};

const accessDeniedBoxStyle = {
  padding: '40px',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark transparent box for the message
  borderRadius: '15px', // Slightly more rounded for better look
  color: '#fff', // White text color
  maxWidth: '450px', // Adjusted max-width to make it look better
  textAlign: 'center',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', // Slight shadow for better contrast
};

const backButtonStyle = {
  padding: '12px 25px',
  backgroundColor: '#5c67ff', // A nice color gradient
  color: '#fff',
  border: 'none',
  borderRadius: '10px',
  cursor: 'pointer',
  fontSize: '16px',
  marginTop: '15px', // Add some margin on top for spacing
  background: 'linear-gradient(135deg, #5c67ff, #8752ff)', // Gradient for a better visual
};

export default CategoryPage;
